import { render, staticRenderFns } from "./Cobranca.vue?vue&type=template&id=5268e259&scoped=true"
import script from "./Cobranca.vue?vue&type=script&lang=js"
export * from "./Cobranca.vue?vue&type=script&lang=js"
import style0 from "./Cobranca.vue?vue&type=style&index=0&id=5268e259&prod&scoped=true&lang=scss"
import style1 from "./Cobranca.vue?vue&type=style&index=1&id=5268e259&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5268e259",
  null
  
)

export default component.exports