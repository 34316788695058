<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalHeader,
  GrModalFooter,
  GrButton,
  GrModalClose,
} from "@/components/GrComponents";
import { useStore } from "@/utils/useStore";
import { useI18n } from "@/utils/useI18n";
const i18n = useI18n()
import { computed, getCurrentInstance, inject, onMounted, ref } from "vue";
const instance = getCurrentInstance()
const vm = instance.proxy


const store = useStore();
const copyData = computed(() => {
  return { ...information.value };
});

const setCopyDataQuestion = computed({
  get() {
    return copyData.value.question;
  },
  set(value) {
    copyData.value.question = value;
  },
});

const setCopyDataAnswer = computed({
  get() {
    return copyData.value.answer;
  },
  set(value) {
    copyData.value.answer = value;
  },
});

async function updateQuestion() {

  try {

    const result = await store.dispatch("questions/updateQuestionAnswer", copyData.value);
    if (result.notValidate) throw ({ validate: result.notValidate, message: result.message })

    getSetModalVelue.value = {};

    vm.$bvToast.toast(i18n.t('opportunities.modal.validate_success'),{
        title:'Greenn Profit' ,
        variant: "success",
        autoHideDelay: 2000,
        appendToast: true,
      })

    emit('updated', result)
  } catch (error) {
    if(error.validate){
      vm.$bvToast.toast(i18n.t(error.message),{
        title:'Greenn Profit' ,
        variant: "info",
        autoHideDelay: 2000,
        appendToast: true,
      })
    }
    console.error(error);
  }
}
const emit = defineEmits('updated')
const getSetModalVelue = inject("getSetModalVelue");
const information = inject("questionSelected");
</script>
<template>
  <div>
    <GrModal :modelValue.sync="getSetModalVelue">
      <GrModalContent class="padding-custom" size="sm">
        <GrModalHeader>
          {{ $t("opportunities.modal.edit_question") }}
        </GrModalHeader>
        <div class="w-full">
          <label for="question" class="label-modal">Pergunta</label>
          <input type="text" class="input-modal" v-model="setCopyDataQuestion" name="input-question"
            placeholder="Pergunta*" />
          <label for="question" class="label-modal">Resposta</label>
          <textarea v-model="setCopyDataAnswer" class="textarea-modal" name="textarea-modal"
            id="textarea-modal"> </textarea>
        </div>
        <GrModalFooter class="footer-position">
          <GrModalClose>
            <GrButton variant="gray-litgh">
              {{ $t("opportunities.modal.button_cancel") }}
            </GrButton>
          </GrModalClose>
          <GrButton @click="updateQuestion()">
            {{ $t("opportunities.modal.button_save_edit") }}
          </GrButton>
        </GrModalFooter>
      </GrModalContent>
    </GrModal>
  </div>
</template>

<style scoped>
.w-full {
  width: var(--w-full);
}

.padding-custom {
  padding: var(--spacing-8) !important;
  padding-bottom: var(--spacing-13) !important;
}

.footer-position {
  justify-content: space-between !important;
}

.label-modal {
  color: var(--old-800);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.input-modal {
  border-radius: 10px;
  border: 1px solid var(--gray-50);
  width: var(--w-full);
  margin-bottom: 24px !important;
  color: var(--old-800) !important;
  background-color: transparent !important;
}

.textarea-modal {
  border-radius: 10px;
  border: 1px solid var(--gray-50);
  width: var(--w-full);
  height: 250px !important;
  flex-shrink: 0;
  color: var(--old-800) !important;
  font-family: Montserrat;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0 !important;
}
</style>
