<template>
  <section class="advanced-questions ml-3">
    <section>
      <FormItem
        :title="$t('opportunities.name')"
        :titleColor="'var(--blue-500)'"
        :sub-title="$t('opportunities.description')"
        class="oportunity-description"
        data-anime="top"
      />
      <FormItem
        v-if="!loading"
        class="oportunity-profit"
        :title="$t('opportunities.profit')"
        :sub-title="$t('opportunities.profit_description')"
      >
        <b-form-checkbox size="lg" v-model="greennProfit" switch />
      </FormItem>
      <div class="w-full-center" v-else>
        <GrSpinner  size="md"/>
      </div>
    </section>
    <section v-if="!loading" class="advanced-tabs">
      <b-tabs class="w-100" content-class="w-100" nav-wrapper-class="w-100">
        <b-tab
          v-for="(tab, index) in tabs.filter((item) => item.show)"
          :key="index"
          lazy
          @click="selectedTab = tab"
        >
          <template #title>
            {{ $t(tab.title) }}
          </template>
        </b-tab>
      </b-tabs>
    </section>
    <component  v-if="!loading"
      :is="selectedTab.component"
      :productQuestions="productQuestions"
      :opportunitySettings="opportunitySettings"
    />
  </section>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import FormItem from "./shared/FormItem.vue";
import { useStore } from "@/utils/useStore";

import QuestionsAnswers from "./Advanced/QuestionsAnswers.vue";
import KittenTimeProduct from "./Advanced/KittenTimeProduct.vue";
import Permissions from "@/utils/permissions.js";
import debounce from 'lodash/debounce';
import { GrSpinner } from "@/components/GrComponents";

const store = useStore();
const loading = ref(false)
const debouncedSetGreennProfit = debounce((event) => {
  store.dispatch("opportunitiesProduct/setActiveProfit", event);
}, 1000);

const greennProfit = computed({
  get() {
    return store.getters["opportunitiesProduct/getGreennProfit"]
        || store.getters['opportunities/getKittenTimeSettings'].length > 0;
  },
  set(event) {
    debouncedSetGreennProfit(event)
  },
});

// Fetch opportunity settings
async function getOpportunitiesSettings() {
  loading.value = true
  await store.dispatch("opportunitiesProduct/getOpportunitiesSettings");
  loading.value = false
}
// Define tabs with component references directly
const tabs = computed(() => [
  {
    title: "opportunities.title",
    component: QuestionsAnswers, // Directly reference the component
    show: Permissions.hasPermission("EditQuestionsAnswers"),
  },
  {
    title: "opportunities.time_title",
    component: KittenTimeProduct, // Directly reference the component
    show: Permissions.hasPermission("EditKittenTimeProduct"),
  },
]);

const opportunitySettings = computed({
  get() {
    let opportunitiesConfig = store.getters["opportunitiesProduct/getOpportunitiesSettings"];
    return JSON.parse(JSON.stringify(opportunitiesConfig));
  },
  set(event) {    
    return store.dispatch("opportunitiesProduct/setOpportunitySettings", event);
  },
});


const productQuestions = computed(() => {
  const getterQuestionAnswers = store.getters["opportunitiesProduct/getOpportunitiesQuestionsAnswers"]
  return getterQuestionAnswers.filter(question => question.product_id == store.getters['productId']);
});

const selectedTab = ref(tabs.value[0]);

onMounted(async () => await getOpportunitiesSettings());
</script>

<style lang="scss">
.oportunity-profit {
  border-bottom: none !important;
  &.form-item .description {
    max-width: 70%;
  }
}

.oportunity-description {
  &.form-item {
    padding-bottom: 0 !important;
    border: none !important;
    .description {
      max-width: 70%;
    }
  }
}

.advanced-questions {
  .advanced-tabs {
    width: 100%;
    margin-bottom: 0 !important;
  }
}
.w-full-center{
  width: var(--wfull);
  justify-content: center;
  display: flex;
  margin-top: var(--w-4);
}
</style>
