<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalFooter,
  GrModalClose,
  GrModalHeader,
  GrButton,
} from "@/components/GrComponents";

import { useStore } from "@/utils/useStore";
import { useToast } from "@/utils/useToast";
import { useI18n } from "@/utils/useI18n";

import { useModal } from "@/composables/useModal";
import { watch, ref, watchEffect } from "vue";

const props = defineProps({
  isGlobal: {
    type: Boolean,
    default: false,
  },

  selectedQuestion: {
    type: Object,
    required: true,
  },
});

const { toast } = useToast();
const { getModalState, closeModal } = useModal();

const modalName = "modal-delete-question";
const modalState = getModalState(modalName);

const store = useStore();
const i18n = useI18n();

const questionSelected = ref({});

async function deleteQuestion() {
  if (props?.isGlobal) {
    await deleteQuestionGlobal();
  } else {
    await deleteQuestionProduct();
  }

  closeModal(modalName);
}

async function deleteQuestionGlobal() {
  const resp = await store.dispatch("questions/deletQuestion", questionSelected.value);

  if (resp) showToastSuccess();
}

async function deleteQuestionProduct() {
  try {
    const resp = await store.dispatch("questions/setDeleteQuestion", {
      questionId: questionSelected.value?.id,
      getList: true,
    });

    if (resp) {
      await store.dispatch("opportunitiesProduct/dropQuestionList", questionSelected.value?.id);
      showToastSuccess();
    }
  } catch (error) {
    await store.dispatch("opportunitiesProduct/getOpportunitiesSettings");
  }
}

function showToastSuccess() {
  toast(i18n.t("opportunities.modal.success_delet"), {
    title: i18n.t("opportunities.profit"),
    variant: "success",
  });
}

watchEffect(() => {
  questionSelected.value = props.selectedQuestion;
});
</script>

<template>
  <div>
    <GrModal :model-value.sync="modalState">
      <GrModalContent size="sm">
        <GrModalHeader />

        <section>
          <div class="icon">
            <img src="@/assets/img/icons/opportunities/lost.svg" alt="" />
          </div>

          <span class="delete-title">{{ $t("opportunities.modal.confirm_delete_title") }}</span>
          <p class="delete-decription">
            {{
              $t("opportunities.modal.confirm_delete_decription", {
                question: questionSelected?.question || "",
              })
            }}
          </p>
        </section>

        <GrModalFooter align="center">
          <GrModalClose class="costom-width">
            <GrButton class="size-button btn-close" variant="gray-light">
              {{ $t("opportunities.modal.button_cancel") }}
            </GrButton>
          </GrModalClose>

          <GrButton variant="red" class="costom-width" @click="deleteQuestion">
            {{ $t("opportunities.modal.confirm_delete") }}
          </GrButton>
        </GrModalFooter>
      </GrModalContent>
    </GrModal>
  </div>
</template>

<style lang="scss" scoped>
.btn-close {
  color: #003b36;
  width: 100%;
}

.costom-width {
  width: 45%;
}

section {
  display: grid;
  gap: 22px;
  place-items: center;

  .icon {
    background-color: #fff3ed;
    width: var(--w-16);
    height: var(--h-16);
    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;
    border: none;

    margin: auto;

    border-radius: var(--radius-full);
  }

  .delete-title {
    width: 100%;
    text-align: center;
    font-size: var(--font-xl);
    font-weight: var(--weight-bold);
    color: var(--old-800);

    margin-top: 26px;
  }
  .delete-decription {
    text-align: center;
    font-size: var(--font-md);
    color: var(--gray-200);

    margin-bottom: 16px;
  }
}
</style>
